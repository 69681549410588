// import localFont from '@next/font/local'
import typography from './typography'
import colors from './colors'

// const Ubuntu = localFont({
//   src: [
//     {
//       path: '../public/fonts/Ubuntu/Ubuntu-Regular.woff2',
//       weight: '400',
//       style: 'normal',
//       display: 'swap',
//       preload: true,
//       fallback: ['sans-serif']
//     },
//     {
//       path: '../public/fonts/Ubuntu/Ubuntu-Regular.woff',
//       weight: '400',
//       style: 'normal',
//       display: 'swap',
//       preload: true,
//       fallback: ['sans-serif']
//     }
//   ],
// })

// const ITCAvantGardeStd = localFont({
//   src: [
//     {
//       path: '../public/fonts/ITCAvantGardeStd/ITCAvantGardeStd-Bk.woff2',
//       weight: '300',
//       style: 'normal',
//       display: 'swap',
//       preload: true
//     },
//     {
//       path: '../public/fonts/ITCAvantGardeStd/ITCAvantGardeStd-Bk.woff',
//       weight: '300',
//       style: 'normal',
//       display: 'swap',
//       preload: true
//     },
//     {
//       path: '../public/fonts/ITCAvantGardeStd/ITCAvantGardeStd-Md.woff2',
//       weight: '500',
//       style: 'normal',
//       display: 'swap',
//       preload: true
//     },
//     {
//       path: '../public/fonts/ITCAvantGardeStd/ITCAvantGardeStd-Md.woff',
//       weight: '500',
//       style: 'normal',
//       display: 'swap',
//       preload: true
//     },
//     {
//       path: '../public/fonts/ITCAvantGardeStd/ITCAvantGardeStd-Bold.woff2',
//       weight: '700',
//       style: 'normal',
//       display: 'swap',
//       preload: true
//     },
//     {
//       path: '../public/fonts/ITCAvantGardeStd/ITCAvantGardeStd-Bold.woff',
//       weight: '700',
//       style: 'normal',
//       display: 'swap',
//       preload: true
//     }
//   ],
// })

export default {
  siteColumns: 12,
  ease: 'ease',
  timing: {
    fast: '0.2s',
    normal: '0.5s',
    slow: '1s',
    verySlow: '5s',
  },
  margins: {
    default: '3.2rem',
    large: '5.2rem',
  },
  defaults: { size: 8 },
  colors,
  fonts: {
    // text: Ubuntu.style.fontFamily,
    // title: ITCAvantGardeStd.style.fontFamily,
    //text: 'Ubuntu',
    //title: 'ITCAvantGardeStd',
    text: 'Rubik',
    title: 'Rubik'
  },
  typography,
  layers: {
    header: 25,
    modal: 22,
    base: 21,
  },
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    ml: 1024,
    xl: 1200,
    xxl: 1400,
  },
}
