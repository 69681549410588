const typography = {
  h1: {
    'font-size': 33,
    'line-height': 33.6,
    mq: {
      lg: {
        'font-size': 75,
        'line-height': 75,
      }
    }
  },
  h2: {
    'font-size': 46,
    'line-height': 48.5,
    mq: {
      lg: {
        'font-size': 67.5,
        'line-height': 70.8,
      }
    }
  },
  h3: {
    'font-size': 33,
    'line-height': 33.6,
    mq: {
      lg: {
        'font-size': 55,
        'line-height': 55.5,
      }
    }
  },
  h4: {
    'font-size': 33,
    'line-height': 33.6,
    mq: {
      lg: {
        'font-size': 40,
        'line-height': 40,
      }
    }
  },
  h5: {
    'font-size': 25,
    'line-height': 27.7,
    mq: {
      lg: {
        'font-size': 30,
        'line-height': 33,
      }
    }
  },
  subheading1: {
    'font-size': 18,
    'line-height': 21.6,
    mq: {
      lg: {
        'font-size': 22,
        'line-height': 31.5,
      }
    }
  },
  subheading2: {
    'font-size': 16,
    'line-height': 19.2,
    mq: {
      xl: {
        'font-size': 20,
        'line-height': 24,
      }
    }
  },
  paragraph1: {
    'font-size': 16,
    'line-height': 20,
    mq: {
      lg: {
        'font-size': 20,
        'line-height': 24,
      }
    }
  },
  paragraph2: {
    'font-size': 15,
    'line-height': 19,
    mq: {
      lg: {
        'font-size': 18,
        'line-height': 20,
      }
    }
  },
  paragraph3: {
    'font-size': 13,
    'line-height': 16.2,
    mq: {
      lg: {
        'font-size': 16,
        'line-height': 21,
        // font-size: clamp(14px, 1vw, 16px);
        // line-height: 21px;
      }
    }
  },
  paragraph4: {
    'font-size': 11,
    'line-height': 12.64,
    mq: {
      lg: {
        'font-size': 14,
        'line-height': 18,
      }
    }
  },
  button1: { 'font-size': 16 },

  link1: {
    'font-size': 16,
    'line-height': 19.2,
    mq: {
      lg: {
        'font-size': 20,
        'line-height': 24,
      }
    }
  },
  link2: {
    'font-size': 14,
    'line-height': 16.8,
    mq: {
      lg: {
        'font-size': 16,
        'line-height': 19.2,
      }
    }
  },
  link3: {
    'font-size': 12,
    'line-height': 14.4,
    mq: {
      lg: {
        'font-size': 14,
        'line-height': 16.8,
      }
    }
  },
  inputPlaceholder1: {
    'font-size': 14,
    'line-height': 16.09,
    mq: {
      lg: {
        'font-size': 20,
        'line-height': 22.98,
      }
    }
  },
  inputValue1: {
    'font-size': 14,
    'line-height': 16.09,
    mq: {
      lg: {
        'font-size': 20,
        'line-height': 22.98,
      }
    }
  },
  label1: {
    'font-size': 16,
    'line-height': 20,
    mq: {
      lg: {
        'font-size': 20,
        'line-height': 24,
      }
    }
  },
  menuItem: {
    'font-size': 16,
    'line-height': 19.5,
  }
}

export default typography
