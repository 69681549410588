const colors = {
  primary: {
    default: (opacity) => `hsla(213, 100%, 25%,  ${opacity || 1})`,
    dark1: 'hsla(213, 100%, 20%, 1)',
  },
  secondary: { default: 'hsla(201, 100%, 40%, 1)', },
  grayscale: {
    white: (opacity) => `hsla(0, 0%, 100%, ${opacity || 1})`,
    gray1: 'hsla(0, 0%, 93%, 1)',
    gray2: 'hsla(0, 0%, 89%, 1)',
    gray3: 'hsla(0, 0%, 60%, 1)',
    gray4: 'hsla(0, 0%, 38%, 1)',
    gray5: 'hsla(0, 0%, 13%, 1)',
    black: (opacity) => `hsla(0, 0%, 0%, ${opacity || 1})`,
  },
  status: {
    success: 'hsla(130, 64%, 49%, 1)',
    warning: 'hsla(36, 100%, 47%, 1)',
    error: 'hsla(7, 91%, 53%, 1)',
  },
  background: {
    home: 'hsl(201, 100%, 40%, 55%)',
    beige: 'hsla(43, 20%, 93%, 1)',
    lightBlue: 'hsla(215, 29%, 92%, 1)',
  }
}


export default colors

