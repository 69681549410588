import React from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'


import ThemeProvider from 'contexts/Theme'
import ResponsiveProvider from 'contexts/Responsive'
import GeneralProvider from 'contexts/General'
import MenuProvider from 'contexts/Menu'
import GTMProvider from 'contexts/GTM'

gsap.registerPlugin(ScrollTrigger)

function MyApp({
  Component,
  pageProps,
  router,
}) {
  return (
    <GeneralProvider
      generalOptions={{
        ...pageProps.options,
        ...pageProps.additionalData,
      }}
    >
      <GTMProvider>
        <ThemeProvider>
          <ResponsiveProvider>
            <MenuProvider
              menus={pageProps.menus}
              currentPage={pageProps.currentPage}
            >
              <Component
                key={router.asPath}
                {...pageProps}/>
            </MenuProvider>
          </ResponsiveProvider>
        </ThemeProvider>
      </GTMProvider>
    </GeneralProvider>
  )
}

export default MyApp
