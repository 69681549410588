import theme from './config'

export function rem(...values) {
  return values.map(px => `${+px / 16}rem`).join(' ')
}

const processCss = (css) => {
  let injectedCss = ''
  if (typeof css === 'string') return css
  for (const [key, value] of Object.entries(css)) {
    let processedValue = value
    if (key === 'font-size' || key === 'line-height') processedValue = rem(value)
    injectedCss += `${key}: ${processedValue}; `
  }

  return injectedCss
}

export const mediaQuery = {}

for (const key in theme.breakpoints) {
  mediaQuery[key] = function (css) {
    return `
      @media screen and (min-width: ${theme.breakpoints[key]}px) {
        ${processCss(css)}
      }
    `
  }
}


export function col(...values) {
  return values.map(c => `calc(${c} / ${theme.siteColumns} * 100vw);`)
}

export function color(key, variant = 'default') {
  if (!theme.colors[key]) return 'inherit'
  return theme.colors[key][variant] ? theme.colors[key][variant] : theme.colors[key]
}

export function spacing(scalar = 1) {
  return rem(Number(scalar) * theme.defaults.size)
}

export function typo(key) {
  const rules = []

  if (theme.typography[key]) {
    for (const [rule, ruleValue] of Object.entries(theme.typography[key])) {
      if (rule === 'mq') {
        for (const [mq, mqValue] of Object.entries(ruleValue)) {
          rules.push(mediaQuery[mq](mqValue))
        }
      } else {
        let processedValue = ruleValue
        if (rule === 'font-size' || rule === 'line-height') processedValue = rem(ruleValue)
        rules.push(`${rule}: ${processedValue};`)
      }
    }

    return [...rules]
  }
  return rules
}

export const checkBreakpoint = {
  tablet: ({ width }) => (width >= theme.breakpoints.md),
  desktop: ({ width }) => (width >= theme.breakpoints.lg),
  wide: ({ width }) => (width >= theme.breakpoints.xl),
  not: {
    tablet: ({ width }) => (width < theme.breakpoints.md),
    desktop: ({ width }) => (width < theme.breakpoints.lg),
    wide: ({ width }) => (width < theme.breakpoints.xl)
  }
}


export const size = (value, max = false) => {
  if (Array.isArray(value)) {
    const valWidth = !!Number(value[0]) ? `${value[0]}px` : value[0]
    const valHeight = !!Number(value[1]) ? `${value[1]}px` : value[1]

    return  `
      ${max ? 'max-' : ''}width: ${valWidth};
      ${max ? 'max-' : ''}height: ${valHeight};
    `
  }

  const val = !!Number(value) ? `${value}px` : value
  return `
    ${max ? 'max-' : ''}width: ${val};
    ${max ? 'max-' : ''}height: ${val};
  `
}

const buildHelper = (value, properties) => {
  let css = ''

  properties.forEach((prop, i) => {
    let val
    if (Array.isArray(value)) {
      if (!value[i]) val = 'auto'

      val = !!Number(value[i]) ? `${rem(value[i])}` : value[i]
    } else {
      val = !!Number(value) ? `${rem(value)}` : value
    }

    css = `
        ${css}
        ${prop}: ${val};
      `
  })

  return css
}


export const paddingY = (value) => {
  return buildHelper(value, ['padding-top', 'padding-bottom'])
}

export const paddingX = (value) => {
  return buildHelper(value, ['padding-left', 'padding-right'])
}

export const marginY = (value) => {
  return buildHelper(value, ['margin-top', 'margin-bottom'])
}

export const marginX = (value) => {
  return buildHelper(value, ['margin-left', 'margin-right'])
}

export const degToRad = (deg) => {
  return deg * (Math.PI / 180.0)
}
