import React, { createContext } from 'react'

export const GeneralContext = createContext({ generalOptions: {}, })

const GeneralProvider = ({
  generalOptions,
  children
}) => (
  <GeneralContext.Provider
    value={{ generalOptions }}
  >
    {children}
  </GeneralContext.Provider>
)

export default GeneralProvider
