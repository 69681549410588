import { createGlobalStyle } from 'styled-components'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
const GlobalStyles = createGlobalStyle`
    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
        border: 0;
        font: inherit;
        font-size: 100%;
        margin: 0;
        padding: 0;
        vertical-align: baseline;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section,
    picture {
        display: block;
    }

    body {
        line-height: 1;
        overscroll-behavior-y: none;
        background-color: ${({ theme }) => theme.colors.background.beige};
    }

    blockquote,
    q {
        quotes: none;
    }

    blockquote::before,
    blockquote::after,
    q::before,
    q::after {
        content: '';
        content: none;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    *,
    *::before,
    *::after {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        outline: none;
        -webkit-tap-highlight-color: transparent;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    input,
    button {
      font-family: inherit;
    }

    :root {
      /* global css variables */
      --Bg-Landing: #F0EFEA;
      --White: #FFFFFF;
      --Blue-Main: #0085CC;
      --Blue-Primary: rgb(0, 57, 128);
      --Blue-Secondary: #2F80AB;
      --Blue-Third: #003A7F;
      --Blue-Light: #5BC4F1;
      --Yellow-Primary: #FABC43;
      --Purple-Light: #DA516D;
      --Purple-Strong: #A52241;
      --Green-Strong: #4A5334;
      --Green-Light: #637C3A;
      /* Buttons positions */
      --Position-Button-01: translate(calc(0% + 215px / 6), 0%);
      --Position-Button-02: translate(0%, calc(0% - 215px / 3));
      --Position-Button-03: translate(0%, calc(0% - 215px / 5));
      --Position-Button-04: translate(0, calc(0% - 215px / 3));
      --Position-Button-05: translate(15%, calc(0% - 215px / 2));
      --Position-Button-06: translate(calc(0% - 215px / 4), calc(-19% - 215px / 2));
      --Position-Button-07: translate(calc(-20% - 215px / 2), calc(0% + 215px / 3));
      /* Button hover */
      --Button-Hover: scale(1.15);
      --Border-color: rgba(0, 0, 0, 0.4);
    }

    // Basic Styles
    html {
        font-weight: 400;
        font-family: ${({ theme }) => theme.fonts.text};
        box-sizing: border-box;
        width: 100%;
        scroll-behavior: initial;
        overflow-x: hidden;

        *, *:before, *:after {
            box-sizing: border-box;
        }
    }

    body {
        display: block !important;
        width: 100%;
        overflow-x: hidden;
        min-height: 100vh;
    }

    html,
    body {
      min-height: 100%;
      height: auto;
      overscroll-behavior: none;

    }
    html.lenis {
      height: auto;
    }

    .lenis.lenis-smooth {
      scroll-behavior: auto;
    }

    .lenis.lenis-smooth [data-lenis-prevent] {
      overscroll-behavior: contain;
    }

    .lenis.lenis-stopped {
      overflow: hidden;
    }

    .lenis.lenis-scrolling iframe {
      pointer-events: none;
    }

    .CookieDeclaration{
      max-width: 650px;
      * {
        ${({ theme }) => theme.typo('paragraph3')}
      }
    }
`
export default GlobalStyles
