import { createGlobalStyle } from 'styled-components'

const FontStyles = createGlobalStyle`

  @font-face {
    font-family: 'Ubuntu';
    src: url('/fonts/Ubuntu/Ubuntu-Regular.woff2') format('woff2'),
          url('/fonts/Ubuntu/Ubuntu-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }


  @font-face {
    font-family: 'ITCAvantGardeStd';
    src: url('/fonts/ITCAvantGardeStd/ITCAvantGardeStd-Bk.woff2') format('woff2'),
          url('/fonts/ITCAvantGardeStd/ITCAvantGardeStd-Bk.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'ITCAvantGardeStd';
    src: url('/fonts/ITCAvantGardeStd/ITCAvantGardeStd-Md.woff2') format('woff2'),
          url('/fonts/ITCAvantGardeStd/ITCAvantGardeStd-Md.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'ITCAvantGardeStd';
    src: url('/fonts/ITCAvantGardeStd/ITCAvantGardeStd-Bold.woff2') format('woff2'),
          url('/fonts/ITCAvantGardeStd/ITCAvantGardeStd-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

`
export default FontStyles;
