import React from 'react'
import { ThemeProvider } from 'styled-components'
import {
  theme,
  GlobalStyles,
  FontStyles,
  helpers,
} from 'theme'

export default ({ children }) => {
  return (
    <ThemeProvider theme={{
      ...theme, ...helpers
    }}>
      <GlobalStyles />
      <FontStyles />
      {children}
    </ThemeProvider>
  )
}
